// import pin from "../../images/map-pin.png";

export default (theme) => ({
  Marker: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontFamily: theme.bodyFont,
    padding: 3,
    textAlign: "center",
    whiteSpace: "nowrap",
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
});
