import React, { useState } from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/styles";

import styles from "./styles";
import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";

export default function LocationSearch({ factors, className }) {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const [name, setName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [radius, setRadius] = useState("");
  const [factor, setFactor] = useState("");

  function handleSubmit(e) {
    if (e) e.preventDefault();

    navigate("/distributors", { state: { name, postcode, radius, factor } });
  }

  return (
    <div className={classes.stockistForm + " " + className}>
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: "Find a stockist",
          level: 2,
          textColor: "foreground_primary",
          backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <form onSubmit={handleSubmit}>
        <div className="name">
          <label htmlFor="name">Name</label>
          <input
            name="name"
            placeholder="Name"
            value={name}
            onBlur={(e) => setName(e.target.value)}
          />
        </div>
        <div className="postcode">
          <label htmlFor="postcode">Postcode</label>
          <input
            type="postcode"
            value={postcode}
            onBlur={(e) => setPostcode(e.target.value)}
          />
        </div>
        <div className="radius">
          <label htmlFor="radius">Radius</label>
          <select
            name="radius"
            defaultValue=""
            value={radius}
            onBlur={(e) => setRadius(e.target.value)}
          >
            <option disabled value="">
              --
            </option>
            <option value={20}>20 miles</option>
            <option value={50}>50 miles</option>
            <option value={100}>100 miles</option>
          </select>
        </div>
        <div className="factor-type">
          <label htmlFor="factor-type">Factor Type</label>
          <select
            name="factor-type"
            defaultValue=""
            value={factor}
            onBlur={(e) => setFactor(e.target.value)}
          >
            <option disabled value="">
              --
            </option>
            {factors.edges.map((factor) => {
              const { slug, title } = factor.node;
              return (
                <option key={slug} value={slug}>
                  {title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="actions">
          <input type="submit" value="search" />
        </div>
      </form>
    </div>
  );
}
