import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"

import LocationOverview from "../components/Location/LocationOverview"
import LocationSignUp from "../components/Location/LocationSignUp"
import LocationSearch from "../components/Location/LocationSearch"

class Location extends Component {
  render() {
    // console.log(this.props);

    return (
      <Layout
        meta={{
          ...this.props.data.wpLocation.Meta,
          canonical: this.props.data.wpLocation.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpLocation.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="location"
          current={{
            title: this.props.data.wpLocation.title,
            slug: this.props.data.wpLocation.slug,
            uri: this.props.data.wpLocation.uri,
          }}
        />
        <LocationOverview
          title={this.props.data.wpLocation.title}
          locationData={this.props.data.wpLocation.LocationInformation}
        />
        <LocationSearch
          factors={this.props.data.allWpStockist}
          className="mb-0"
        />
        <LocationSignUp />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpLocation(id: { eq: $id }) {
      id
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      id
      title
      uri
      slug
      LocationInformation {
        address1
        address2
        address3
        address4
        city
        country
        postcode
        telephone
        fax
        email
        stockist {
          ... on WpStockist {
            id
            title
            stockists {
              logo
            }
          }
        }
        companyInfo
        openingTimes
        latitude
        longitude
      }
    }
    allWpStockist {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Location
