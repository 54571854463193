export default (theme) => ({
  locationOverview: {
    marginBottom: 50,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginBottom: 100,
    },
    "& .go-back": {
      display: "block",
      fontSize: 18,
      padding: 0,
      lineHeight: 1.5,
      border: "none",
      backgroundColor: "transparent",
      color: theme.palette.secondary.main,
      letterSpacing: "0.016em",
      textTransform: "uppercase",
      fontFamily: theme.headerFont,
      outline: "none",
      margin: "24px 0",
      textDecoration: "none",
      [theme.breakpoints.up("md")]: {
        margin: "50px 0 36px",
      },
      "&::before": {
        content: '""',
        borderLeft: "2px solid " + theme.palette.secondary.main,
        borderBottom: "2px solid " + theme.palette.secondary.main,
        display: "inline-block",
        width: 10,
        height: 10,
        transform: "rotate(45deg)",
        marginRight: 6,
        marginTop: -4,
      },
    },
    "& h4": {
      marginBottom: 32,
    },
    "& .location-data": {
      backgroundColor: "white",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row-reverse",
        flexWrap: "wrap",
        "& .map": {
          flex: "0 0 62.5%",
          minWidth: "62.5%",
        },
      },
      "& .content": {
        padding: 24,
        [theme.breakpoints.up("md")]: {
          padding: 36,
          flex: "0 0 37.5%",
          minWidth: "37.5%",
        },
        "& img": {
          maxWidth: 190,
          marginBottom: 32,
          display: "block",
          [theme.breakpoints.up("md")]: {
            marginBottom: 50,
          },
        },
        "& h5": {
          color: theme.palette.secondary.main,
          textTransform: "uppercase",
          marginBottom: 6,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontFamily: theme.headerFont,
          fontWeight: 400,
          fontSize: 16,
          marginTop: 32,
        },
        "& p": {
          fontSize: 16,
          letterSpacing: "0.016em",
          fontFamily: "Overpass",
          lineHeight: 1.5,
          marginTop: 0,
          marginBottom: 22,
          "&.phone, &.fax, &.email": {
            marginBottom: 5,
            "& span": {
              color: theme.palette.secondary.main,
              textTransform: "uppercase",
              marginBottom: 6,
              letterSpacing: "0.016em",
              lineHeight: 1.5,
              fontFamily: theme.headerFont,
              fontWeight: 400,
              fontSize: 16,
              marginTop: 32,
            },
            "& a": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              textDecoration: "none",
              transition: "color .3s ease-in-out",
              "&::hover, &::focus": {
                color: theme.palette.secondary.main,
              },
            },
          },
          "&.opening": {
            "& strong": {
              width: 50,
              display: "inline-block",
            },
          },
        },
      },
    },
  },
});
