import React from "react";
// import { navigate } from 'gatsby';
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import GoogleMapReact from "google-map-react";
import Marker from "../../StockistSearch/Marker";

import Link from "../../Link";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductOverview(props) {
  // console.log(props);
  const classes = useStyles();

  const { longitude, latitude } = props.locationData;
  const lat = Number(latitude);
  const lng = Number(longitude);

  return (
    <div className={`location-overview  ${classes.locationOverview}`}>
      <Link to="/distributors" className="go-back">
        Back
      </Link>
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: ReactHtmlParser(props.title),
          level: 4,
          textColor: "primary",
          backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <div className="location-data">
        <div className="map">
          <div style={{ height: "100%", width: "100%", minHeight: "500px" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCTv5gH57Owv4nY85Pw90H5_4iw3awM2HU",
              }}
              defaultCenter={{
                lat,
                lng,
              }}
              defaultZoom={14}
              yesIWantToUseGoogleMapApiInternals
              className="map"
            >
              <Marker lat={lat} lng={lng} />
            </GoogleMapReact>
          </div>
        </div>
        <div className="content">
          <h5>Find Us</h5>
          <p className="address">
            {props.locationData.address1 !== null && (
              <span>
                {props.locationData.address1} <br />
              </span>
            )}
            {props.locationData.address2 !== null && (
              <span>
                {props.locationData.address2} <br />
              </span>
            )}
            {props.locationData.address3 !== null && (
              <span>
                {props.locationData.address3} <br />
              </span>
            )}
            {props.locationData.address4 !== null && (
              <span>
                {props.locationData.address4} <br />
              </span>
            )}
            {props.locationData.city !== null && (
              <span>
                {props.locationData.city} <br />
              </span>
            )}
            {props.locationData.country !== null && (
              <span>
                {props.locationData.country} <br />
              </span>
            )}
            {props.locationData.postcode !== null && (
              <span>
                {props.locationData.postcode} <br />
              </span>
            )}
          </p>
          {props.locationData.telephone !== null && (
            <p className="phone">
              <span>Tel:</span>{" "}
              <Link to={"tel:" + props.locationData.telephone}>
                {props.locationData.telephone}
              </Link>
            </p>
          )}
          {props.locationData.fax !== null && (
            <p className="fax">
              <span>Fax:</span>{" "}
              <Link to={"tel:" + props.locationData.fax}>
                {props.locationData.fax}
              </Link>
            </p>
          )}
          {props.locationData.email !== null && (
            <p className="email">
              <span>Email:</span>{" "}
              <Link to={"mailto:" + props.locationData.email}>
                {props.locationData.email}
              </Link>
            </p>
          )}
          {props.locationData.companyInfo !== "" && (
            <>
              <h5>Company Info</h5>
              <p>{ReactHtmlParser(props.locationData.companyInfo)}</p>
            </>
          )}
          {props.locationData.openingTimes !== "" && (
            <>
              <h5>Opening Times</h5>
              <p className="opening">
                {ReactHtmlParser(props.locationData.openingTimes)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
